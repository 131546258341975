































































































































































































































































.vue-image-crop-upload::v-deep {
  .vicp-wrap {
    @media (max-width: 600px) {
      width: 100%;
      height: auto;
      padding: 40px 5px;
    }

    .vicp-img-container {
      @media (max-width: 600px) {
        margin: 0 auto;
      }
    }

    .vicp-crop-right {
      @media (max-width: 600px) {
        margin: 0 auto;
      }
    }

    .vicp-range {
      @media (max-width: 600px) {
        margin: 30px auto !important;
      }
    }

    .vicp-crop {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.el-tabs {
  padding-bottom: 15px;
}
