































































































































.user-balance {
  .el-tabs__header {
    margin-bottom: 30px;
  }
}
