




































































































































.items {
  .item {
    border-bottom: 1px solid #e5e5e5;

    &-property {
      font-family: "Raleway Regular", serif;
      font-size: 16px;

      .el-icon-circle-close {
        font-size: 20px;
        color: #F56C6C;
      }

      .el-icon-circle-check {
        font-size: 20px;
        color: #1F9F43;
      }
    }

    &-value {
      font-family: "Raleway Bold", serif;
      font-size: 16px;
    }
  }
}

.edit-profile-btn, .change-password-btn {
  @media(max-width: 768px) {
    font-size: 14px;
  }
}
